<template>
  <div class="w-100 p-l-30 p-r-30">
    <div class="card mt-3">
      <div class="card-header">
        <img src="../assets/images/sidebar/ic_menu_tournament_off.png">
        <span class="mx-3">第57回島津全日本室内テニス選手権大会</span>
      </div>
      <div class="card-body">
        <div class="row">
          <p class="mx-auto col-5 mt-5">
            スキャナーでQRコードを読み込んでください。 もしくは、入場管理番号を直接入力してください。
          </p>
        </div>
        <div class="row mt-4">
          <input class="col-5 mx-auto form-control qr-input" placeholder="入場管理番号">
        </div>
        <div class="text-center loading">
          <p class="pt-5">待機中</p>
          <p class="mb-5 loading-dots">. . .</p>
        </div>
        <div class="row m-t-8">
          <button class="col-5 mx-auto btn admin-home-custom-button">詳細</button>
        </div>
      </div>
      <!--            <Modal body="斎藤 美夏 のステータスを大会承認済に変更します。 よろしいですか？"-->
      <!--                   button-title="click"-->
      <!--                   cancel-title="キャンセル"-->
      <!--                   ok-title="承認"-->
      <!--                   :is-checkbox=isCheckbox-->
      <!--                   checkbox-title="全ての「異常あり」にも反映"-->
      <!--                   title="admission control">-->
      <!--            </Modal>-->
    </div>
  </div>
</template>

<script>
import Modal from '../components/modal/Modal'

export default {
  name: "AdmissionControl",
  components: {Modal},
  data() {
    return {
      isCheckbox: true
    }
  }
}
</script>

<style lang="scss" scoped>
.qr-input {
  width: 45% !important;
}

::placeholder {
  color: #C7AEB1;
}

.loading {
  color: #E0B1B7;
}

.loading-dots {
  font-size: 30px;
  font-weight: 900;
}

.admin-home-custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  width: 45%;
  height: 44px !important;
}
</style>