<template>
  <div>
    <div class="custom-contianer">
      <div class="left">
        <a class="ashen-link" href="">健康記録参照</a> >
        <a class="ashen-link" href="">関係者健康記録一覧</a> >
        <span class="">関係者健康記録詳細</span>
      </div>

      <div class="date-div spbetween">
        <span>< <a class="ashen-link" @click="getPrevDate">{{ prevDate === null ? '' : prevDate }}</a></span>
        <span><a class="ashen-link" @click="getNextDate">{{ nextDate === null ? '' : nextDate }}</a> ></span>
      </div>
      <div v-if="defaultScreen" class="card mt-3">
        <div class="card-header color-33 bg-transparent">
          <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/><span
            style="padding-left: 5px">{{ tournamentUserDetails.tournamentName }}</span>
        </div>
        <div class="card-body pl-3">
          <div class="form-holder">
            <div class="row">
              <div class="col-6">
                <label>姓名</label>
                <input v-model="tournamentUserDetails.lastName" class="form-control mt-1 filter-select"
                       placeholder="斎藤 美夏" readonly type="text">
                <label>日付</label>
                <input v-model="date" class="form-control mt-1 filter-select" readonly type="text">
                <label>体温</label>
                <input v-model="tournamentUserDetails.bodyTemp" class="form-control mt-1 filter-select"
                       placeholder="36.4" readonly type="text">
                <label>平熱を超える発熱</label>
                <input v-model="tournamentUserDetails.answers[0].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
                <label>咳やのどの痛みなど風邪の症状</label>
                <input v-model="tournamentUserDetails.answers[1].value"
                       class="form-control mt-1 filter-select" placeholder="有り" readonly
                       type="text">
                <label>だるさ(倦怠感)、息苦しさ(呼吸困難)</label>
                <input v-model="tournamentUserDetails.answers[2].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
                <label>嗅覚や味覚の異常</label>
                <input v-model="tournamentUserDetails.answers[3].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
                <label>体が重く感じる、疲れやすい等</label>
                <input v-model="tournamentUserDetails.answers[4].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
                <label>新型コロナウイルス感染症陽性とされた者との 濃厚接触の有無</label>
                <input v-model="tournamentUserDetails.answers[5].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">

              </div>
              <div class="col-6">
                <label>入場管理番号</label>
                <input v-model="tournamentUserDetails.userId" class="form-control mt-1 filter-select"
                       placeholder="T376981" readonly type="text">
                <label class="pd">ステータス</label>
                <div class="col">
                  <span v-if="tournamentUserDetails.healthStatus === 1"
                        class="badge badge-status border-0 height-28 width-104 badge-danger-light"><i
                      class="fas fa-times-circle"></i>入場拒否</span>
                  <span v-if="tournamentUserDetails.healthStatus === 2 && tournamentUserDetails.approval !== true"
                        class="badge badge-status border-0 height-28 width-104 badge-danger-light"><i
                      class="fas fa-user-times"></i>異常あり</span>
                  <span v-if="tournamentUserDetails.healthStatus === 3"
                        class="badge badge-status border-0 height-28 width-104 badge-warning-light"><i
                      class="fa fa-question-circle"></i>未入力</span>
                  <span v-if="tournamentUserDetails.approval === true && tournamentUserDetails.healthStatus === 2"
                        class="badge badge-status border-0 height-28 width-104 badge-success-light"><i
                      class="far fa-calendar-check"></i>大会承認済</span>
                  <span v-if="tournamentUserDetails.healthStatus === 5"
                        class="badge badge-status border-0 height-28 width-104 badge-success-light"><i
                      class="fas fa-user-check"></i>異常なし</span>
                </div>
                <label>新型コロナウイルス感染症陽性とされた者との<br>濃厚接触の有無</label>
                <input class="form-control mt-1 filter-select" placeholder="有り" type="text">
                <label>同居家族や身近な知人に感染が疑われる方がい <br>る場合</label>
                <input v-model="tournamentUserDetails.answers[6].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
                <label>過去14日以内に政府から入国制限、入国後の経 <br>過観察を必要とされている国、地域への渡航又<br> は当該在住者との濃厚接触がある場合</label>
                <input v-model="tournamentUserDetails.answers[7].value"
                       class="form-control mt-1 filter-select" placeholder="無し" readonly
                       type="text">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label>備考欄</label>
                <textarea class="form-control" cols="50" readonly rows="10">
                       {{ tournamentUserDetails.remarks }}
                </textarea>

              </div>

            </div>
            <div class="button_holder">
              <div class="row">
                <div class="col-6">
                  <div class="mt-3">
                    <Modal v-if="tournamentUserDetails.healthStatus === 2 && tournamentUserDetails.approval !== true" :data="tournamentUserDetails"
                           :user-record-approval="approval_on" body=" のステータスを大会承認済に変更します。 " button-title="承認"
                           cancel-title="キャンセル"
                           checkbox-title="全ての「異常あり」にも反映"
                           confirmationMsg="よろしいですか？"
                           is-checkbox=true
                           ok-title="承認" title="大会承認"
                           @getAllCheck="allCheckApprove"></Modal>
                    <Modal v-if="tournamentUserDetails.approval === true && tournamentUserDetails.healthStatus === 2" :data="tournamentUserDetails"
                           :user-record-approval="approval_off" body=" のステータスは大会承認済みになっています。 " button-title="承認解除"
                           cancel-title="キャンセル"
                           checkbox-title="全ての「大会承認」にも反映"
                           confirmationMsg="解除してもよろしいですか？"
                           is-checkbox=true
                           ok-title="承認解除" title="大会承認解除"
                           @getAllCheck="allCheckApprove"></Modal>
                  </div>
                </div>
                <div class="col-6">
                  <div class="mt-3">
                    <button class="btn btn-primary height-44 font-16 pt-1 text-center float-end custom_btn bg_black"
                            @click="reject()">
                      入場拒否
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div v-if="approveScreen">
        <div class="card mt-3">
          <div class="card-header">
            <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/><span style="padding-left: 5px">第57回島津全日本室内テニス選手権大会</span>
          </div>
          <div class="card-body pl-3">
            <div class="form-holder">
              <div class="row">
                <div class="col-6">
                  <label>姓名</label>
                  <input class="form-control mt-1 filter-select" placeholder="斎藤 美夏" type="text">
                  <label>日付</label>
                  <input class="form-control mt-1 filter-select" placeholder="12/02" type="date">
                  <label>体温</label>
                  <input class="form-control mt-1 filter-select" placeholder="36.4" type="text">
                  <label>平熱を超える発熱</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>咳やのどの痛みなど風邪の症状</label>
                  <input class="form-control mt-1 filter-select" placeholder="有り" type="text">
                  <label>だるさ(倦怠感)、息苦しさ(呼吸困難)</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>嗅覚や味覚の異常</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>体が重く感じる、疲れやすい等</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>新型コロナウイルス感染症陽性とされた者との 濃厚接触の有無</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">

                </div>
                <div class="col-6">
                  <label>入場管理番号</label>
                  <input class="form-control mt-1 filter-select" placeholder="T376981" type="text">
                  <label class="pd">ステータス</label>
                  <div class="col">
                    <span class="badge badge-status border-0 height-28 width-104 badge-calendar-check"><i
                        class="far fa-calendar-check" data-v-fae5bece=""></i>大会承認済</span>
                  </div>
                  <label>新型コロナウイルス感染症陽性とされた者との<br>濃厚接触の有無</label>
                  <input class="form-control mt-1 filter-select" placeholder="有り" type="text">
                  <label>同居家族や身近な知人に感染が疑われる方がい <br>る場合</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>過去14日以内に政府から入国制限、入国後の経 <br>過観察を必要とされている国、地域への渡航又<br>
                    は当該在住者との濃厚接触がある場合</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>姓名</label>
                  <textarea id="w3review-deauth" class="form-control" cols="50" name="w3review"
                            rows="10">
                  入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメン
                </textarea>

                </div>

              </div>
              <div class="button_holder">
                <div class="row">
                  <div class="col-6">
                    <div class="mt-3">
                      <button class="btn btn-primary height-44 font-16 pt-1 text-center mx-4 custom_btn"
                              @click="deauth()">
                        承認解除
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="rejectScreen">
        <div class="card mt-3">
          <div class="card-header">
            <img src="../assets/images/sidebar/ic_menu_tournament_off.png"/><span style="padding-left: 5px">第57回島津全日本室内テニス選手権大会</span>
          </div>
          <div class="card-body pl-3">
            <div class="form-holder">
              <div class="row">
                <div class="col-6">
                  <label>姓名</label>
                  <input class="form-control mt-1 filter-select" placeholder="斎藤 美夏" type="text">
                  <label>日付</label>
                  <input class="form-control mt-1 filter-select" placeholder="12/02" type="date">
                  <label>体温</label>
                  <input class="form-control mt-1 filter-select" placeholder="36.4" type="text">
                  <label>平熱を超える発熱</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>咳やのどの痛みなど風邪の症状</label>
                  <input class="form-control mt-1 filter-select" placeholder="有り" type="text">
                  <label>だるさ(倦怠感)、息苦しさ(呼吸困難)</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>嗅覚や味覚の異常</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>体が重く感じる、疲れやすい等</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>新型コロナウイルス感染症陽性とされた者との 濃厚接触の有無</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">

                </div>
                <div class="col-6">
                  <label>入場管理番号</label>
                  <input class="form-control mt-1 filter-select" placeholder="T376981" type="text">
                  <label class="pd">ステータス</label>
                  <div class="col">
                    <span class="badge badge-status border-0 height-28 width-104 badge-times-circle"><i
                        class="fas fa-times-circle" data-v-fae5bece=""></i>入場拒否</span>
                  </div>
                  <label>新型コロナウイルス感染症陽性とされた者との<br>濃厚接触の有無</label>
                  <input class="form-control mt-1 filter-select" placeholder="有り" type="text">
                  <label>同居家族や身近な知人に感染が疑われる方がい <br>る場合</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                  <label>過去14日以内に政府から入国制限、入国後の経 <br>過観察を必要とされている国、地域への渡航又<br>
                    は当該在住者との濃厚接触がある場合</label>
                  <input class="form-control mt-1 filter-select" placeholder="無し" type="text">
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>姓名</label>
                  <textarea id="w3review-cancelRejection" class="form-control" cols="50"
                            name="w3review" rows="10">
                  入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメント入力したコメン
                </textarea>

                </div>

              </div>
              <div class="button_holder">
                <div class="row">
                  <div class="col-6">
                    <div class="mt-3">
                      &nbsp;
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="mt-3">
                      <button class="btn btn-primary height-44 font-16 pt-1 text-center mx-4 custom_btn bg_black"
                              @click="cancelRejection()">
                        入場拒否解除
                      </button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</template>

<script>
import {mapGetters} from 'vuex'
import {apiConfig} from '@/constants'
import Modal from '../components/modal/Modal'
import Common from "jsadminclient/common";

export default {
  name: "HealthRecord",
  components: {Modal},
  props: {
    tournamentId: {type: Number, default: null},
    userId: {type: String, default: null},
    date: {type: String, default: null}
  },
  data() {
    return {
      apiConfig: apiConfig,
      defaultScreen: true,
      rejectScreen: false,
      approveScreen: false,
      deauthScreen: false,
      cancelRejectionScreen: false,
      admissionStatus: 1, //1=default, 2=approved, 3=reject, 4=cancelReject
      healthStatus: 1, //1=normal, 2=abnormal
      tournamentUserDetails: {},
      prevDate: '',
      nextDate: '',
      approval: true,
      allCheck: false,

    }
  },
  mounted() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
    this.getTournamentRecordDetails()
  },
  computed: {
    ...mapGetters(['config']),
  },
  methods: {
    reject() {
      this.rejectScreen = true
      this.defaultScreen = false
    },
    approve() {
      this.defaultScreen = false
      this.approveScreen = true
    },
    deauth() {
      this.defaultScreen = true
      this.deauthScreen = false
      this.rejectScreen = false
      this.approveScreen = false
    },
    cancelRejection() {
      this.defaultScreen = true,
          this.cancelRejectionScreen = false
      this.rejectScreen = false

    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },

    allCheckApprove(allCheck) {
      this.allCheck = allCheck
    },
    async approval_on() {
      this.approval = true;
      this.getUserRecordApproval();
    },
    async approval_off() {
      this.approval = false;
      this.getUserRecordApproval();
    },
    async getUserRecordApproval() {
      try {
        var item = {
          date: this.date,
          approval: this.approval,
          allCheck: this.allCheck
        }
        var api = new AdminApi.TournamentApi(this.getApiConfig());
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdApprovalPostAsync(
            this.tournamentId,
            this.userId,
            item
        )

        this.getTournamentRecordDetails()

        if (this.tournamentUserDetails != null) {
          this.loading = false
          console.log(this.tournamentUserDetails)
        }
      } catch (e) {
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },

    getPrevDate() {
      this.date = this.prevDate
      this.getTournamentRecordDetails()
    },

    getNextDate() {
      this.date = this.nextDate
      this.getTournamentRecordDetails()
    },

    async getTournamentRecordDetails() {
      try {
        var api = new AdminApi.TournamentApi(this.getApiConfig());
        this.tournamentUserDetails = await api.tournamentsTournamentIdUserRecordsUserIdDetailGetAsync(
            this.tournamentId,
            this.userId,
            this.date
        )

        if (this.tournamentUserDetails != null) {
          this.prevDate = this.tournamentUserDetails.prevDate
          this.nextDate = this.tournamentUserDetails.nextDate
          this.loading = false
        }
      } catch (e) {
        this.loading = false;
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-contianer {
  padding: 0 22px;
}

.left {
  margin: auto;
  width: 100%;
  text-align: left;
  height: 36px;
  line-height: 20px;
  border-bottom: 1px solid #e2e2e2;
}

.date-div.spbetween {
  height: 76px;
  border-bottom: 1px solid #e2e2e2;
  line-height: 76px;
}

.content_wrapper {
  padding: 0 32px;
}

.content {
  top: unset !important;
}

.nvlink-container {
  border-bottom: 1px solid #e2e2e2;
}

.spbetween {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.filter-select {
  font-size: 13px;
  height: 48px !important;
  line-height: 13px;
}

.form-holder {
  padding: 0 80px;
}

.pd {
  padding: 5px 0;
}

textarea {
  background: #FAF7F9;
  min-height: 207px;
}

.custom_btn {
  width: 350px;
}

.bg_black {
  background: #333333 0% 0% no-repeat padding-box;
}

.custom-contianer {
  label {
    padding: 17px 0 10px 0;

    &.pd {
      padding: 20px 0;
    }
  }

  .badge-calendar-check {
    background-color: #F0FFEA;
    color: #7EC100;
  }

  .badge-times-circle {
    background-color: #FFEAEB;
    color: #C1001B;
  }
}
</style>
